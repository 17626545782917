var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      staticClass: "base-selection",
      attrs: {
        label: _vm.label,
        "label-for": "input",
        "label-class": { "required-field": _vm.required },
      },
    },
    [
      _c(
        "b-overlay",
        {
          attrs: { show: _vm.loading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c("pulse-loader", {
                    attrs: {
                      loading: _vm.loading,
                      color: _vm.fill,
                      size: "10px",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-dropdown",
            {
              staticClass: "w-100",
              attrs: {
                variant: "empty",
                "toggle-class": "p-0",
                "menu-class": "mt-2 search-item-menu a-shadow-sm",
                "no-caret": "",
              },
            },
            [
              _c(
                "template",
                { slot: "button-content" },
                [
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.error,
                          show: _vm.validate && _vm.v.$error,
                          trigger: "manual",
                          placement: "bottom",
                          classes: ["light"],
                        },
                        expression:
                          "{\n            content: error,\n            show: validate && v.$error,\n            trigger: 'manual',\n            placement: 'bottom',\n            classes: ['light']\n          }",
                      },
                    ],
                    attrs: {
                      placeholder: _vm.placeholder,
                      readonly: "",
                      state: _vm.validate && _vm.v.$error,
                      autocomplete: "off",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.v.$touch()
                      },
                    },
                    model: {
                      value: _vm.selected[_vm.itemValue],
                      callback: function ($$v) {
                        _vm.$set(_vm.selected, _vm.itemValue, $$v)
                      },
                      expression: "selected[itemValue]",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-dropdown-group",
                [
                  _vm.searchable
                    ? _c("b-form-input", {
                        key: "search-input",
                        staticClass: "search-item-input mb-2",
                        attrs: {
                          type: "text",
                          placeholder: "Search item...",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.searchItem,
                          callback: function ($$v) {
                            _vm.searchItem = $$v
                          },
                          expression: "searchItem",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.filteredItems
                    ? _c(
                        "vue-perfect-scrollbar",
                        {
                          staticClass: "scroll",
                          attrs: {
                            settings: {
                              suppressScrollX: true,
                              wheelPropagation: false,
                            },
                          },
                        },
                        _vm._l(_vm.filteredItems, function (item, index) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: `item-${index}`,
                              attrs: { active: _vm.setItemActive(index) },
                              on: {
                                click: function ($event) {
                                  return _vm.onItemClick(item)
                                },
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(item.name))])]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }