import moment from 'moment'
import { role } from '@/constants/config'
import { mapGetters } from 'vuex'

export const helperMixin = {
  methods: {
    getNameInitials(fullName) {
      return fullName
        .split(' ')
        .map((n) => n[0])
        .join('')
    },
    formatDate(date) {
      return moment(date).format('DD MMM, YYYY HH:mm')
    },
    formatShortDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    formatCustomDate(date, format) {
      return date ? moment(new Date(date)).format(format) : date
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    },
    formatFileNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    },
    getCurrentDate() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      return today
    },
    calculateAveragePerc(value, count, multiple) {
      return ((value / (count === 0 ? 1 : count)) * multiple).toFixed(1).replace(/\.0$/, '')
    },
    objectExisted(obj) {
      return Object.keys(obj).length > 0
    },
    checkCurrentDate(date) {
      return moment(new Date(date)).isSame(moment(), 'day')
    },
    isEmpty(value) {
      return value == null || value.length === 0
    },
    calculatePercentage(partialValue, totalValue) {
      if (totalValue <= 0) {
        return 0
      } else {
        let result = (100 * partialValue) / totalValue
        return result > 0 ? result : 0
      }
    },
    isBeforeDate(date1, date2) {
      return moment(new Date(date1)).isBefore(new Date(date2))
    },
    containsObject(key, obj, list) {
      return list.some((elem) => elem[key] === obj[key])
    },
    convertLocalTimestamp(date) {
      return moment.utc(date).local().format('X')
    },
    createUUID() {
      const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
      return pattern.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0
        const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    isAdmin() {
      let self = this
      return self.currentUser && self.currentUser.role === role.admin
    },
    hasManagementRole() {
      let self = this
      return (
        self.currentUser &&
        (self.currentUser.role === role.admin || self.currentUser.role === role.leader)
      )
    },
    hasAdminRole() {
      let self = this
      return self.currentUser && self.currentUser.role === role.admin
    },
    hasLeaderRole() {
      let self = this
      return self.currentUser && self.currentUser.role === role.leader
    }
  },
  filters: {
    formatTitle(str) {
      return _.startCase(_.toLower(str))
    },
    formatStartCamelCase(str) {
      return _.startCase(_.camelCase(str))
    }
  }
}
